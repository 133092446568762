import "react-toggle/style.css";
import Toggle from "react-toggle";
import { toast } from "react-toastify";
import { FiFilter } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { Formik, Form, Field } from "formik";
import { PiArrowLeft } from "react-icons/pi";
import Star from "../../assets/icons/Star.png";
import { TbPencilMinus } from "react-icons/tb";
import Avatar2 from "../../assets/Avatar2.png";
import News from "../../assets/icons/News.png";
import { formatDistanceToNow } from "date-fns";
import { TfiAngleRight } from "react-icons/tfi";
import { HiOutlinePhoto } from "react-icons/hi2";
import Shield from "../../assets/icons/shield.png";
import BlogPostImages from "../../assets/Photo.png";
import Header from "../../components/globals/Header";
import Speaker from "../../assets/icons/speaker.png";
import Projects from "../../assets/icons/projects.png";
import Calendar from "../../assets/icons/calendar.png";
import { useDispatch, useSelector } from "react-redux";
import ImageOne from "../../assets/on-boarding-bg.png";
import { CREATE_POST_SCHEMA } from "../../utils/validation";
import { getPostList, createPost } from "../../redux/action";
import InfiniteScroll from "react-infinite-scroll-component";
import NormalInput from "../../components/inputs/NormalInput";
import CustomLoader from "../../components/Loader/CustomLoader";
import { CREATE_POST_INITIAL_VALUE } from "../../utils/initialValue";
import StandardButton from "../../components/buttons/StandardButton";
import HomerFeedCard from "../../components/HomeFeedCard/HomerFeedCard";
import CreatePostModal from "../../components/ModalComponent/CreatePostModal";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import {
  Col,
  Row,
  Modal,
  Label,
  Input,
  Button,
  Container,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

const categories = [
  {
    label: "General Announcements",
    icon: <img width="20" src={Speaker} alt="General Announcements" />,
    description:
      "For important updates, news, and general reminders that do not fit into other categories.",
  },
  {
    label: "Events & Socials",
    icon: <img width="20" src={Calendar} alt="Events & Socials" />,
    description:
      "For community events, social gatherings, activities, and related reminders.",
  },
  {
    label: "Projects & Maintenance",
    icon: <img width="20" src={Projects} alt="Projects & Maintenance" />,
    description:
      "For updates and reminders on construction, maintenance, and other project-related posts.",
  },
  {
    label: "Safety & Security",
    icon: <img width="20" src={Shield} alt="Safety & Security" />,
    description:
      "For crime alerts, safety tips, lookout posts, and related reminders.",
  },
  {
    label: "Meeting & Agendas",
    icon: <img width="20" src={Star} alt="Meeting & Agendas" />,
    description:
      "For announcements, reminders, and details about board meetings and agendas.",
  },
  {
    label: "Community News",
    icon: <img width="20" src={News} alt="Community News" />,
    description:
      "For broader neighborhood news, stories, and relevant information.",
  },
];

const images = [Avatar2, ImageOne, BlogPostImages];

const HomeFeed = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const fileInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [posts, setPosts] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [allPosts, setAllPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [postType, setPostType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState("");
  const [otherModalOpen, setOtherModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infiniteLoading, setInfiniteLoading] = useState(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };
  const handleToggleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const toggleOtherModal = () => {
    setOtherModalOpen(!otherModalOpen);
  };

  const toggleFilterModal = () => {
    setFilterModalOpen(!filterModalOpen);
  };
  const filteredPosts = useMemo(() => {
    const filtered = allPosts.filter((post) =>
      selectedCategory
        ? post.label.toLowerCase() === selectedCategory.toLowerCase()
        : true
    );
    console.log("Filtered Posts:", filtered);
    return filtered;
  }, [allPosts, selectedCategory]);

  // Compute sorted posts based on selected sort option
  const sortedPosts = useMemo(() => {
    const sorted = selectedSortBy
      ? [...filteredPosts].sort((a, b) => {
        if (selectedSortBy === "newest") {
          return new Date(b.createdAt) - new Date(a.createdAt);
        } else if (selectedSortBy === "oldest") {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        return 0;
      })
      : filteredPosts;

    console.log("Sorted Posts:", sorted);
    return sorted;
  }, [filteredPosts, selectedSortBy]);

  const handleCategorySelect = (category) => {
    // setPostType(category.label);
    // toggleModal();
    // toggleOtherModal();

    setPostType(category.label);
    setModalOpen(false);
    setOtherModalOpen(true);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const data = await dispatch(
          getPostList(token, selectedCategory, selectedSortBy)
        );
        const sortedPosts = data.posts.sort(
          (a, b) => b.createdAt - a.createdAt
        );
        setAllPosts(sortedPosts);
        setPosts(sortedPosts.slice(0, 5));
        setHasMore(sortedPosts.length > 5);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [dispatch, token]);

  const fetchMoreData = () => {
    setInfiniteLoading(true);
    setTimeout(() => {
      if (allPosts.length > 5) {
        const nextPage = page + 1;
        const newPosts = allPosts.slice(0, nextPage * 5);
        setPosts(newPosts);
        setPage(nextPage);
        setHasMore(newPosts.length < allPosts.length);
      } else {
        setHasMore(false);
      }
      setInfiniteLoading(false);
    }, 1000);
  };

  const handleFileChange = (event, setFieldValue) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setFieldValue("files", selectedFiles);
  };

  const removeFile = (index, setFieldValue) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    setFieldValue("files", newFiles);
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const resetFilters = () => {
    setSortBy("");
    setPostType("");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("label", postType);
      formData.append("content", values.content);
      formData.append("title", values.heading);
      formData.append("isPinned", values.pinPost);
      values.files.forEach((file, index) => {
        formData.append(`files`, file);
      });

      await dispatch(createPost(formData, token));
      const data = await dispatch(getPostList(token));
      const sortedPosts = data.posts.sort((a, b) => b.createdAt - a.createdAt);
      setAllPosts(sortedPosts);
      setPosts(sortedPosts.slice(0, 5));
      toast.success("Post created successfully");
      toggleOtherModal();
    } catch (error) {
      console.error("Error creating post:", error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleApplyFilter = () => {
    setPosts(sortedPosts);
    toggleFilterModal();
  };

  return (
    <section className="home-feeds-bg">
      <Header />
      <Container className="p-3">
        <Row className="justify-content-center">
          <Col md={8} xs={12} className="my-3">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="fw-bold">HOA Updates</h2>
              <div className="d-flex align-items-center">
                <StandardButton
                  className="border-0 btn-blue me-2 rounded-32"
                  onClick={toggleModal}
                >
                  <span className="d-flex justify-content-between align-items-center">
                    <TbPencilMinus size={20} />
                    <p className="mb-0 px-2" style={{
                      fontWeight: "700",
                      color: "rgba(255, 255, 255, 1)",
                      lineHeight: "22px",
                      fontSize: "16px"
                    }}>Create Post</p>
                  </span>
                </StandardButton>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={8}>
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={infiniteLoading && <CustomLoader />}
              endMessage={<p style={{ textAlign: "center" }}>No more posts</p>}
            >
              {posts.map((post) => (
                <HomerFeedCard
                  allCategories={categories}
                  post={post}
                  key={post.id}
                  avatar={post?.association?.image}
                  sliderImages={post.sliderImages}
                  timestamp={formatDistanceToNow(
                    new Date(post.createdAt * 1000),
                    { addSuffix: true }
                  )}
                  heading={post.title}
                  buttonText={post.label}
                  name={post?.association?.name}
                  btnIcon={
                    categories.find((category) => category.label === post.label)
                      ?.icon || null
                  }
                  content={post.content}
                  BlogPost={post.files[0]}
                  setAllPosts={setAllPosts}
                  setPosts={setPosts}
                />
              ))}
            </InfiniteScroll>
          </Col>
        </Row>

        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          contentClassName="rounded-5"
          style={{ maxWidth: "650px" }}
        >
          <div className="d-flex align-items-center justify-content-between pt-4 px-4 pb-0">
            <h3 className="font-32px f-7" style={{ color: "rgba(51, 51, 51, 1)" }} >Create a Post</h3>
            <IoIosClose
              size={40}
              onClick={toggleModal}
              className="rounded-circle border bg-light p-2"
            />
          </div>
          <ModalBody className="p-4">
            <h6 className="f-7" style={{ fontSize: "18px" }}>Select a Post Type:</h6>
            {categories.map((category) => (
              <div
                className="p-0 mb-2 borderRadius-16 text-dark"
                style={{ height: "100px", borderStyle: "solid", borderColor: "rgba(197, 197, 197, 1)", borderWidth: "0.5px" }}
                key={category.label}
              >
                <div
                  className="d-flex align-items-center justify-content-between borderRadius-16  bg-light-gray p-3 h-100 cursor-pointer"
                  onClick={() => handleCategorySelect(category)}
                >
                  <div className="d-flex align-items-center">
                    <div className="p-1"> {category.icon}</div>
                    <div className="ms-2">
                      <h6 className="mb-0 f-5 text-black">{category.label}</h6>
                      <small className="text-muted">
                        {category.description}
                      </small>
                    </div>
                  </div>
                  <TfiAngleRight size={18} />
                </div>
              </div>
            ))}
          </ModalBody>
        </Modal>

        <CreatePostModal
          isOpen={otherModalOpen}
          toggle={toggleOtherModal}
          headerTitle="Create a Post"
          maxWidth="600px"
        >
          <Formik
            initialValues={CREATE_POST_INITIAL_VALUE}
            validationSchema={CREATE_POST_SCHEMA}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form className="w-100">
                <PiArrowLeft
                  onClick={toggleModal}
                  className="cursor-pointer "
                />

                <StandardButton className="CategoryBtn text-dark ms-3">
                  <span className="d-flex align-items-center">
                    <img width="22" src={Speaker} alt="General Announcements" />
                    <p className="mb-0 px-2">{postType}</p>
                  </span>
                </StandardButton>
                <div className="mb-3">
                  <Field
                    name="heading"
                    type="text"
                    placeholder="Type here...."
                    component={NormalInput}
                    label="Heading"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    label=""
                    type="textarea"
                    name="content"
                    component={NormalInput}
                    placeholder="Share with your HOA community"
                  />
                </div>
                <input
                  multiple
                  type="file"
                  className="d-none"
                  ref={fileInputRef}
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                />
                <div
                  onClick={triggerFileInput}
                  className="border text-center d-flex align-items-center justify-content-center p-3 rounded-16 my-2"
                  style={{ cursor: "pointer" }}
                >
                  <HiOutlinePhoto
                    size={24}
                    className="add-photo-icon"
                    onClick={(event) => {
                      event.stopPropagation();
                      triggerFileInput();
                    }}
                  />
                  <span className="px-2" style={{ fontWeight: "700", fontSize: "16px" }}>Photo</span>
                </div>
                <div className="d-flex flex-wrap">
                  {files.map((file, index) => (
                    <div
                      key={index}
                      className="position-relative mx-1 rounded-3 overflow-hidden"
                    >
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Uploaded"
                        width={100}
                      />
                      <IoIosClose
                        size={20}
                        onClick={() => removeFile(index, setFieldValue)}
                        className="position-absolute top-0 end-0 bg-light rounded-circle p-1 m-1"
                      />
                    </div>
                  ))}
                </div>
                <div className="my-3 d-flex align-items-center">
                  <Toggle
                    checked={isChecked}
                    onChange={(event) => {
                      handleToggleChange(event);
                      setFieldValue("pinPost", !isChecked);
                    }}
                    icons={false}
                  />
                  <p className="m-0 ms-2">Pin Post to top of Community Feed</p>
                </div>
                <Button
                  type="submit"
                  className="btn-blue rounded-3 w-100 py-3 border-0 rounded-4"
                  style={{ fontWeight: "700", fontSize: "16px" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Posting..." : "Post"}
                </Button>
              </Form>
            )}
          </Formik>
        </CreatePostModal>
        <Modal
          isOpen={filterModalOpen}
          toggle={toggleFilterModal}
          contentClassName="rounded-5"
          style={{ maxWidth: "650px" }}
        >
          <div className=" w-100 pt-4 px-4 pb-0">
            <h2 className="text-center fw-bold">Filters</h2>
          </div>

          <ModalBody className="p-4">
            <FormGroup>
              <Label for="sortSelect" className="small Color-dark ps-2">
                Sort by
              </Label>
              <Input
                type="select"
                id="sortSelect"
                value={selectedSortBy}
                className="inputPadding rounded-16"
                onChange={(e) => setSelectedSortBy(e.target.value)}
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="categorySelect" className="small Color-dark ps-2">
                Filter Post Type
              </Label>
              <Input
                type="select"
                id="categorySelect"
                className="inputPadding rounded-16"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.label} value={category.label}>
                    {category.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="border-0">
            <Row className="justify-content-center w-100">
              <Col md={6}>
                <Button
                  className="p-3 rounded-4 w-100 btn btn-light border-0"
                  onClick={() => {
                    // Reset filters
                    setSelectedCategory("");
                    setSelectedSortBy("");
                    toggleFilterModal();
                  }}
                >
                  Reset All
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  className="p-3 rounded-4 w-100 text-white border-0 btn-blue"
                  onClick={handleApplyFilter}
                >
                  Apply Filters
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </Container>
    </section>
  );
};

export default HomeFeed;
